import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";

// markup
const NotFoundPage = () => {
  const [cookies] = useCookies(["hevelop-language"]);

  useEffect(() => {
    const language = "en";

    if (cookies["hevelop-language"] !== undefined) {
      navigate(`/${cookies["hevelop-language"]}/not-found`);
    } else {
      navigate(`/${language}/not-found`);
    }
  });

  return null;
};

export default NotFoundPage;
